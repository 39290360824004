//===========================================
//
// Homepage
//
//===========================================


// Layout
//===========================================
html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #ebebeb;

  // Sticky footer
  @media #{$md-xl} {
    display: flex;
    flex-direction: column;
  }

    & > .content-wrap {
      // Sticky footer
      @media #{$md-xl} {
        flex: 1 0 auto;
      }
    }

    .page-content {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .site-footer {
      // Sticky footer
      @media #{$md-xl} {
        flex-shrink: 0;
      }
    }
}

.site-main {
  padding: 30px 0;
}


// Navbar
//===========================================
.navbar {
  $navHeight: 40px;

  background-color: $primaryColor;

    .navbar-header {
      position: relative;

        & > .container {
          display: flex;
          align-items: center;
        }

        .nav-header-link {
          display: block;
          margin-left: auto;
          margin-right: -15px;
          height: $navHeight;
          line-height: $navHeight;
          padding-left: 15px;
          padding-right: 15px;
          background-color: $secondaryColor;
          color: #fff;
          transition: background-color $transDur;

          &:hover,
          &:focus {
            background-color: lighten( $secondaryColor, 10% );
          }
        }
    }

    .navbar-toggle {
      width: $navHeight;
      height: $navHeight;
      border: none;
      background-color: #000;
      cursor: pointer;
      transition: background-color $transDur;
      padding: 0 8px;

      &:focus {
        outline: none;
      }

      &.collapsed {
        background-color: $primaryColor;

        &:hover,
        &:focus {
          background-color: darken( $primaryColor, 15% );
        }
      }

        .icon-bar {
          display: block;
          position: relative;
          width: 24px;
          height: 2px;
          border-radius: 1px;
          background-color: #fff;

          & + .icon-bar {
            margin-top: 4px;
          }
      }
    }

    .navbar-search-toggle {
      margin-left: 10px;
      width: $navHeight;
      height: $navHeight;
      border: none;
      background: none;
      background-color: $primaryColor;
      color: #000;
      cursor: pointer;
      transition: background-color $transDur,
                  color $transDur;
      padding: 0 8px;

      &:focus {
        outline: none;
      }

      &:hover,
      &:focus {
        background-color: darken( $primaryColor, 15% );
        color: #fff;
      }
    }

    .navbar-collapse {
      overflow: hidden;
      background-color: #000;

        .container {
          padding-top: 50px;
          padding-bottom: 30px;
        }
    }

    .navbar-search {
      position: absolute;
        top: 100%;
      z-index: 100;
      height: $navHeight;
      width: 100%;
      background-color: #fff;

      &.collapsed {
        display: none;
      }

      &.expanded {
        display: block;
      }

        & > .container {
          display: flex;
        }

        .form-control {
          flex-grow: 1;
          height: $navHeight;
          border: none;
          @include placeholderText(#ccc);

          &:focus {
            outline: none;
          }
        }

        .navbar-search-toggle {
          border-radius: 50%;
          background-color: #fff;
          font-size: 26px;
          color: #721c24;

          &:hover,
          &:focus {
            color: #fff;
            background-color: #721c24;
          }

        }
    }

    .site-nav {
      margin: 0;
      padding: 0;
      list-style-type: none;

        .nav-item {
          font-weight: 700;

          & + .nav-item {
            margin-top: 10px;
          }
        }

        .nav-link {
          color: #fff;
          transition: color $transDur;

          &:hover,
          &:focus {
            color: $primaryColor;
          }
        }
    }

    .nav-share {
      display: flex;
      align-items: center;
      margin-top: 75px;

        .share-title {
          margin: 0;
          font-size: 20px;
          font-weight: 700;
          color: $primaryColor;
        }

        .share-items {
          margin: 0 0 0 20px;
          padding: 0;
          list-style-type: none;
          @include clearfix;

            .share-item {
              float: left;

              & + .share-item {
                margin-left: 15px;
              }
            }
        }

        .share-link {
          display: block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          background-color: #fff;
          color: #000;
          text-align: center;
          transition: background-color $transDur;

          &:hover,
          &:focus {
            background-color: $primaryColor;
          }
        }
    }

    .contact {
      display: block;
      margin-top: 40px;

        p {
          font-weight: 700;
          color: #fff;

          a {
            border-bottom: 2px solid transparent;
            color: $primaryColor;
            transition: border-color $transDur;

            &:hover,
            &:focus {
              border-color: $primaryColor;
            }
          }
        }
    }
}


// Site Header
//===========================================
.page-header {
  margin: 0;
  padding: 15px 0;
  border-radius: 0;
  background-color: #000;

    .logo {
      display: block;
      margin: auto;
      max-width: 200px;
    }

    .header-toggle {
      display: flex;
      justify-content: center;
      margin: 15px 0 0 0;
      padding: 0;
      list-style-type: none;

        .toggle {
          position: relative;

          & + .toggle {
            margin-left: 10px;
            padding-left: 12px;

            &:before {
              content: "";
              display: block;
              position: absolute;
                left: 0;
                top: 50%;
              transform: translateY( -50% );
              height: 14px;
              width: 3px;
              background-color: $primaryColor;
            }
          }
        }

        .toggle-link {
          padding: 0;
          border: none;
          background: none;
          font-weight: 700;
          color: #7f7f7f;
          cursor: pointer;
          transition: color $transDur;

          &:hover,
          &:focus {
            color: $primaryColor;
          }

          &.active {
            color: #fff;
            pointer-events: none;
          }
        }
    }
}

// Season Nav
//===========================================
.season-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

    .nav-title {
      margin: 0;
      font-size: 18px;
      color: #000;
    }

    .season-list {
      margin: 0 0 0 10px;
      padding: 0;
      list-style-type: none;
      @include clearfix;

        .season {
          float: left;

          & + .season {
            margin-left: 5px;
          }

          &.hidden {
            display: none;
          }
        }

        .season-link {
          display: block;
          width: 45px;
          height: 30px;
          line-height: 30px;
          border-radius: 20px;
          background-color: #d7d7d7;
          font-size: 17px;
          font-weight: 700;
          color: #fff;
          text-align: center;
          transition: background-color $transDur;

          &:hover,
          &:focus {
            background-color: darken( #d7d7d7, 10% );
          }

          &.active {
            background-color: $primaryColor;
            pointer-events: none;
          }

          @media (max-width: 420px) {
            width: 35px;
          }
        }
    }
}


// Accordion
//===========================================
.accordion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;

    & > .accordion-panel {
      padding-bottom: 5px;
    }

    &.active {
      display: block;
    }

  .subpanels-wrapper {

    p {
      padding-left: 25px;
      padding-right: 25px;

      a {
        color: $primaryColor;
      }
    }
    .subpanel p.subtitle {
      padding-left: 0px;
      padding-right: 0px;
    }

    .netflix_link {
      color: #e50914;
      font-weight: bold;
      text-decoration: none;
      display: block;
      padding-left: 25px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .description-wrapper {
    padding-bottom: 15px;
    padding-top: 20px;
  }

    .panel-item {
      display: block;
      padding: 15px;
      border-left: 10px solid;
      background-color: #fff;
      transition: background-color $transDur;

      .panel-title {
        margin: 0;
        font-size: 20px;
        color: #000;
        transition: color $transDur;

          .episode {
            margin-right: 15px;
            color: #ccc;
            transition: color $transDur;
          }
      }

      .subtitle {
        margin-bottom: 0;
        color: #7a7a7a;
        transition: color $transDur;
      }

      .occurrence-list {
          margin:  10px 0 0 0;
          padding: 0;
          list-style-type: none;

            .occurrence {
              color: #7a7a7a;
              transition: color $transDur;

              & + .occurrence {
                margin-top: 10px;
              }

                .timestamp {
                  color: #000;
                  transition: color $transDur;
                }
            }
        }
    }

    .description-wrapper {
      border-left: 10px solid;
      background-color: #fff;
      transition: background-color $transDur;

    }

    .subpanels {
      margin: 5px 0 0 14px;
      padding-left: 14px;
      border-left: 3px dotted #000;
      list-style-type: none;

        .subpanel + .subpanel {
          margin-top: 5px;
        }

        .panel-item {
          border-color: $primaryColor;
        }
    }

  &.episode-list {
      // Top level panels (blue)
    .description-wrapper {
      border-color: $secondaryColor;
    }
      .accordion-panel > .panel-item {
        border-color: $secondaryColor;

        /*
        &:hover {
          background-color: lighten( $secondaryColor, 5% );

          .panel-title,
          .panel-title .episode,
          .occurrence-list .occurrence,
          .occurrence-list .occurrence .timestamp {
            color: #fff;
          }
        }
        */

        &.low-freq {
          border-color: lighten( $secondaryColor, 15% );
        }

        &.high-freq {
          border-color: darken( $secondaryColor, 15% );
        }
      }

      // Top level panel when open
      .accordion-panel.expanded > .panel-item {
        background-color: lighten( $secondaryColor, 5% );

        .panel-title,
        .panel-title .episode {
          color: #fff;
        }
      }

      // Secondary panels (orange)
      .subpanel > .panel-item {
        border-color: $primaryColor;

        &:hover,
        &:focus {
          background-color: lighten( $primaryColor, 10% );

          .panel-title,
          .subtitle,
          .occurrence-list .occurrence,
          .occurrence-list .occurrence .timestamp {
            color: #fff;
          }
        }

        &.low-freq {
          border-color: lighten( $primaryColor, 15% );
        }

        &.high-freq {
          border-color: darken( $primaryColor, 15% );
        }
      }
  }

    &.joke-list {
      .description-wrapper {
        border-color: $primaryColor;
      }
      // Top level panels (orange)
      .accordion-panel > .panel-item {
        border-color: $primaryColor;

        /*
        &:hover {
          background-color: lighten( $primaryColor, 10% );

          .panel-title,
          .panel-title .episode,
          .occurrence-list .occurrence,
          .occurrence-list .occurrence .timestamp {
            color: #fff;
          }
        }
        */

        &.low-freq {
          border-color: lighten( $primaryColor, 15% );
        }

        &.high-freq {
          border-color: darken( $primaryColor, 15% );
        }
      }

      // Top level panel when open
      .accordion-panel.expanded > .panel-item {
        background-color: lighten( $primaryColor, 5% );

        .panel-title,
        .panel-title .episode {
          color: #fff;
        }
      }

      // Secondary panels (blue)
      .subpanel > .panel-item {
        border-color: $secondaryColor;

        &:hover,
        &:focus {
          background-color: lighten( $secondaryColor, 5% );

          .panel-title,
          .subtitle,
          .panel-title .episode,
          .occurrence-list .occurrence,
          .occurrence-list .occurrence .timestamp {
            color: #fff;
          }
        }

        &.low-freq {
          border-color: lighten( $secondaryColor, 15% );
        }

        &.high-freq {
          border-color: darken( $secondaryColor, 15% );
        }
      }
  }
}

// JS Classes
[data-accordion-panel] {

      [data-accordion-collapse] {
        overflow: hidden;
      }

    &.collapsing {
      height: auto!important; //bs override
    }

    &.collapsed [data-accordion-collapse] {
      display: none;
    }

    &.expanded [data-accordion-collapse] {
      display: block;
      height: auto;
    }
}


// Autocomplete

.ui-autocomplete {
  background-color: white;
  left: 0px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);

  a {
    color: #028fcb;
  }
}