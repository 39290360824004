//===========================================
//
// Base Typography
//
//===========================================

// Additional typography for theme

// Add antialiase
h1, h2, h3, h4, h5, h6, p, li, label, address, blockquote, dt, dd, figcaption, th, td, time {
  @include antialiase;
}

// Link hover/focus styles
a {
  transition: color $transDur;

  &:focus {
    outline: none!important;
  }
}