.joke-icon-1 { background: transparent url('../../images/icons.gif') no-repeat  0 0;}
.joke-icon-2 { background: transparent url('../../images/icons.gif') no-repeat  0 -170px;}
.joke-icon-3 { background: transparent url('../../images/icons.gif') no-repeat  0 -340px;}
.joke-icon-4 { background: transparent url('../../images/icons.gif') no-repeat  0 -510px;}
.joke-icon-5 { background: transparent url('../../images/icons.gif') no-repeat  0 -680px;}
.joke-icon-6 { background: transparent url('../../images/icons.gif') no-repeat  0 -850px;}
.joke-icon-7 { background: transparent url('../../images/icons.gif') no-repeat  0 -1020px;}
.joke-icon-8 { background: transparent url('../../images/icons.gif') no-repeat  0 -1190px;}
.joke-icon-9 { background: transparent url('../../images/icons.gif') no-repeat  0 -1360px;}
.joke-icon-10 { background: transparent url('../../images/icons.gif') no-repeat  0 -1530px;}
.joke-icon-11 { background: transparent url('../../images/icons.gif') no-repeat  0 -1700px;}
.joke-icon-12 { background: transparent url('../../images/icons.gif') no-repeat  0 -1870px;}
.joke-icon-13 { background: transparent url('../../images/icons.gif') no-repeat  0 -2040px;}
.joke-icon-14 { background: transparent url('../../images/icons.gif') no-repeat  0 -2210px;}
.joke-icon-15 { background: transparent url('../../images/icons.gif') no-repeat  0 -2380px;}
.joke-icon-16 { background: transparent url('../../images/icons.gif') no-repeat  0 -2550px;}
.joke-icon-17 { background: transparent url('../../images/icons.gif') no-repeat  0 -2720px;}
.joke-icon-18 { background: transparent url('../../images/icons.gif') no-repeat  0 -2890px;}
.joke-icon-19 { background: transparent url('../../images/icons.gif') no-repeat  0 -3060px;}
.joke-icon-20 { background: transparent url('../../images/icons.gif') no-repeat  0 -3230px;}
.joke-icon-21 { background: transparent url('../../images/icons.gif') no-repeat  0 -3400px;}
.joke-icon-22 { background: transparent url('../../images/icons.gif') no-repeat  0 -3570px;}
.joke-icon-23 { background: transparent url('../../images/icons.gif') no-repeat  0 -3740px;}
.joke-icon-24 { background: transparent url('../../images/icons.gif') no-repeat  0 -3910px;}
.joke-icon-25 { background: transparent url('../../images/jokes/banana-stand.png') no-repeat}
.joke-icon-26 { background: transparent url('../../images/jokes/ostrich.png') no-repeat;}
.joke-icon-27 { background: transparent url('../../images/jokes/anustart.png') no-repeat;}
.joke-icon-28 { background: transparent url('../../images/jokes/hot-mess.png') no-repeat;}
.joke-icon-29 { background: transparent url('../../images/jokes/always-leave-a-note.png') no-repeat;}
.joke-icon-30 { background: transparent url('../../images/jokes/pick-a-lane.png') no-repeat;}

.icon {
    display: block;
    width: 174px;
    height: 155px;
    float: none;
    margin: 0;
    background-size: 170px 4080px;
    z-index: 501;

    &.joke-icon-25{background-size: auto 155px;margin-left: 20px;margin-top: 16px;margin-bottom: 7px;}
    &.joke-icon-25.left,&.joke-icon-25.right{margin-top:0;}
    &.joke-icon-26 { background: transparent url('../../images/jokes/ostrich.png') no-repeat; background-size: auto 155px;}
    &.joke-icon-26.right{width: 72px;margin-top: -21px;margin-bottom: 22px;}
    &.joke-icon-27 { background: transparent url('../../images/jokes/anustart.png') no-repeat; background-size: auto 95px;height: 130px;}
    &.joke-icon-27.right{margin-right: 40px;}
    &.joke-icon-28 { background: transparent url('../../images/jokes/hot-mess.png') no-repeat; background-size: auto 50%;}
    &.joke-icon-29 { background: transparent url('../../images/jokes/always-leave-a-note.png') no-repeat; background-size: auto 164px; }
    &.joke-icon-29.right{margin-right: 40px; margin-top: -60px;}
    &.joke-icon-30 {background: transparent url('../../images/jokes/pick-a-lane.png') no-repeat; background-size: auto 133px;margin-right: 0; margin-top: 0;}


    &.joke-icon-1 {margin-top: 0;}

    /* fruit hat */
    &.joke-icon-3 {margin-top: -10px;}

    &.joke-icon-4 {margin-top: -30px;}

    /* banner */
    &.joke-icon-5{margin-top: -45px; height: 119px; margin-left: -15px;}
    /* no touching */
    &.joke-icon-6 {height: 132px; margin-top: -30px}
    /* never nude */
    &.joke-icon-7 {margin-top: -20px;}
    /* plain is Ann */
    &.joke-icon-14 {margin-top: -9px; height: 162px;}

    /* chicken dance */
    &.joke-icon-8{ height: 162px;}
    &.joke-icon-15 { margin-top: -4px; } /* Her? */
    &.joke-icon-18, /* mistake */
    &.joke-icon-20, /* Annyong */
    &.joke-icon-21, /* whence */
    &.joke-icon-23  /* marry me! */
    {margin-top: -43px; height: 127px;}

    &.joke-icon-16 /* hey brother */
    {margin-top: -32px; height: 127px;}
    &.joke-icon-22  /* c'mon! */
    {margin-top: -32px; height: 127px;}
    /* mere fact */
    &.joke-icon-17{height: 124px; margin-top: -30px;}
}

.joke-details {
    .icon {
        &.joke-icon-1 {height: 115px !important; background-position-y: -20px;}
        &.joke-icon-2 { background-position:0 -180px; height: 125px !important;}
        &.joke-icon-4 {background-position:0 -538px;height: 105px !important;}
        &.joke-icon-5 {margin-top: -25px; height: 119px; margin-left: -15px;}
        &.joke-icon-9 {background-position:0 -1380px; height: 105px !important;}
        &.joke-icon-10 {background-position:0 -1560px; height: 115px !important;}
        &.joke-icon-11 {background-position:0 -1713px; height: 135px !important;}
        &.joke-icon-12 {background-position:0 -1880px;}
        &.joke-icon-14 {margin-top: -9px; height: 162px;}
        &.joke-icon-19 {height: 95px !important;background-position: 0 -3107px;}
        &.joke-icon-26 {margin-left: 74px; margin-top: 17px;}
        &.joke-icon-27 {margin-top: 29px;margin-left: 25px;margin-bottom: -35px;}
        &.joke-icon-28 {margin-top: 29px;margin-left: 25px;margin-bottom: -35px;}
        &.joke-icon-29 {margin-left: 24px; margin-top: 10px;}
        &.joke-icon-30 {margin-left: 0; margin-top: 10px;}
    }
}


.big {
    display: block;
    width: 340px;
    height: 340px;
    float: none;
    margin: 0;

    &.joke-icon-1 { background: transparent url('../../images/icons.gif') no-repeat  0 0 ;}
    &.joke-icon-2 { background: transparent url('../../images/icons.gif') no-repeat  0 -340px;}
    &.joke-icon-3 { background: transparent url('../../images/icons.gif') no-repeat  0 -680px;}
    &.joke-icon-4 { background: transparent url('../../images/icons.gif') no-repeat  0 -1020px;}
    &.joke-icon-5 { background: transparent url('../../images/icons.gif') no-repeat  0 -1360px;}
    &.joke-icon-6 { background: transparent url('../../images/icons.gif') no-repeat  0 -1700px;}
    &.joke-icon-7 { background: transparent url('../../images/icons.gif') no-repeat  0 -2040px;}
    &.joke-icon-8 { background: transparent url('../../images/icons.gif') no-repeat  0 -2380px;}
    &.joke-icon-9 { background: transparent url('../../images/icons.gif') no-repeat  0 -2720px;}
    &.joke-icon-10 { background: transparent url('../../images/icons.gif') no-repeat  0 -3060px;}
    &.joke-icon-11 { background: transparent url('../../images/icons.gif') no-repeat  0 -3400px;}
    &.joke-icon-12 { background: transparent url('../../images/icons.gif') no-repeat  0 -3740px;}
    &.joke-icon-13 { background: transparent url('../../images/icons.gif') no-repeat  0 -4080px;}
    &.joke-icon-14 { background: transparent url('../../images/icons.gif') no-repeat  0 -4420px;}
    &.joke-icon-15 { background: transparent url('../../images/icons.gif') no-repeat  0 -4760px;}
    &.joke-icon-16 { background: transparent url('../../images/icons.gif') no-repeat  0 -5100px;}
    &.joke-icon-17 { background: transparent url('../../images/icons.gif') no-repeat  0 -5440px;}
    &.joke-icon-18 { background: transparent url('../../images/icons.gif') no-repeat  0 -5780px;}
    &.joke-icon-19 { background: transparent url('../../images/icons.gif') no-repeat  0 -6120px;}
    &.joke-icon-20 { background: transparent url('../../images/icons.gif') no-repeat  0 -6460px;}
    &.joke-icon-21 { background: transparent url('../../images/icons.gif') no-repeat  0 -6800px;}
    &.joke-icon-22 { background: transparent url('../../images/icons.gif') no-repeat  0 -7140px;}
    &.joke-icon-23 { background: transparent url('../../images/icons.gif') no-repeat  0 -7480px;}
    &.joke-icon-24 { background: transparent url('../../images/icons.gif') no-repeat  0 -7820px;}

    &.joke-icon-12.right {
        margin-bottom: -200px;
    }
    &.joke-icon-19.left {
        margin-top: -60px;
        margin-bottom: -70px;
    }
}

.icon.right.joke-icon-5,
.icon.left.joke-icon-5,
.icon.right.joke-icon-21,
.icon.left.joke-icon-21,
.icon.right.joke-icon-17,
.icon.left.joke-icon-17,
.icon.right.joke-icon-18,
.icon.left.joke-icon-18,
.icon.right.joke-icon-23,
.icon.left.joke-icon-23,
.icon.right.joke-icon-8,
.icon.left.joke-icon-8,
.icon.right.joke-icon-16,
.icon.left.joke-icon-16,
.icon.right.joke-icon-7,
.icon.left.joke-icon-7,
.icon.right.joke-icon-22,
.icon.left.joke-icon-22,
.icon.right.joke-icon-15,
.icon.left.joke-icon-15,
.icon.right.joke-icon-6,
.icon.left.joke-icon-6,
.icon.right.joke-icon-8,
.icon.left.joke-icon-8,
.icon.left.joke-icon-14,
.icon.right.joke-icon-14,
.icon.joke-icon-20.left,
.icon.joke-icon-20.right {
    margin-top: 0
}

.icon.right.joke-icon-23,
.icon.left.joke-icon-23 { background-position: 0 -3758px; }

.icon.joke-icon-20.left,
.icon.joke-icon-20.right{ background-position: 0 -3250px;}


.icon.right.joke-icon-4,
.icon.left.joke-icon-4 {background-position: 0 -530px;height: 105px}
.icon.right.joke-icon-10,
.icon.left.joke-icon-10 {background-position: 0 -1550px;height: 122px}
.icon.right.joke-icon-11,
.icon.left.joke-icon-11 {background-position: 0 -1718px;}
.icon.right.joke-icon-16,
.icon.left.joke-icon-16 {background-position:0 -2588px;height: 104px;}
.icon.right.joke-icon-17,
.icon.left.joke-icon-17 {background-position: 0 -2750px;height: 104px;}
.icon.right.joke-icon-18,
.icon.left.joke-icon-18 {background-position: 0 -2900px;}
.icon.right.joke-icon-21,
.icon.left.joke-icon-21 {background-position: 0 -3429px;}
.icon.right.joke-icon-22,
.icon.left.joke-icon-22 {background-position: 0 -3590px;}
.icon.right.joke-icon-6,
.icon.left.joke-icon-6 {background-position: 0 -860px;}
.icon.right.joke-icon-7,
.icon.left.joke-icon-7 {background-position: 0 -1035px;}

