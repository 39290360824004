//===========================================
//
// Theme Mixins
//
//===========================================

// Convert px to rem
@function rem($size) {
    @return $size / 16px * 1rem;
}

// Adds antialiase to text
@mixin antialiase {
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

//Place holder color and focus color
@mixin placeholderText( $placeholderColor, $focusColor: rgba(255, 255, 255, 0) ) {

  &::-webkit-input-placeholder {
    color: $placeholderColor;
  }

  &:-moz-placeholder {
    color: $placeholderColor;
  }

  &::-moz-placeholder {
    color: $placeholderColor;
  }

  &:-ms-input-placeholder {
    color: $placeholderColor;
  }

  &:focus{
    &::-webkit-input-placeholder {
      color: $focusColor;
    }

    &:-moz-placeholder {
      color: $focusColor;
    }

    &::-moz-placeholder {
      color: $focusColor;
    }

    &:-ms-input-placeholder {
      color: $focusColor;
    }
  }
}